import { GatsbyPageProps, Token } from '@/types';
import { ArticleList } from '../../articles';
import Xxx100WeekMaChartTemplate from '@/templates/crypto/xxx-100-week-moving-average-chart';

export default function Solana100WeekMaChart({ location }: GatsbyPageProps) {
  return (
    <Xxx100WeekMaChartTemplate
      location={location}
      coinAbbreviation="SOL"
      coinFullName="Solana"
      token={Token.SOLUSDT}
      articleId={ArticleList.SOLANA_100_WEEK_MOVING_AVERAGE}
    />
  );
}
